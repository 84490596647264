import {
    Button
} from '@chakra-ui/react'

import theme from '../../Config/theme'

const PrimaryButton = ({ label, isLoading, onClick }) => {
    return (
        <Button
            _active={{}}
            _focus={{}}
            _hover={{}}
            size={'lg'}
            bgColor={theme.default.defaultColor}
            borderRadius={'full'}
            color={'white'}
            isLoading={isLoading || false}
            onClick={onClick}
        >
            {label}
        </Button>
    )
}

export default PrimaryButton