import theme from "../../Config/theme"

import { Highlight, useColorModeValue } from "@chakra-ui/react"

const CustomHighlight = ({ lineColor, query, color, children }) => {
    const textColor = useColorModeValue('#1A202C', '#FFFFFFEB')
    
    return (
        <Highlight query={query} styles={{ borderBottom: `3px solid ${lineColor || theme.default.defaultColor}`, color: color ? color : textColor, whiteSpace: "normal", wordWrap: "break-word", }}>
            {children}
        </Highlight>
    )
}

export default CustomHighlight