import config from '../Config/config'
import axios from 'axios'

const headers = {
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true
}

const handleError = (e) => {
    return {
        data: {
            error: true,
            errorType: 'serverUnavailable',
            message: 'The server is currently unavailable. Please try again later.'
        }
    }
}

const getAccountDetails = async () => {
    try {
        const response = await axios.get(config.API_BASE_URL + '/account/get-account-details', headers)

        return response

    } catch (error) {
        return handleError()
    }
}

export { getAccountDetails }